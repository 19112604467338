import React, {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import { Paper } from '../layout';
import { onDevice } from '../constants/theme';
import {
  RecoveryDone,
  RecoveryEmail,
  RecoveryIrmaSession,
  RecoveryVerificationCode,
} from '../components';
import { AuthContext } from '..';
import { H1, Icon } from '@formelio/react-components';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { RecoveryStage } from '../types/recovery-stage';

const Main = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const PageWrapper = styled.div`
  padding-top: 140px;

  ${onDevice.mobile} {
    margin: 32px;
    padding-top: 64px;
  }
`;

const Footer = styled.div`
  height: 40px;
  width: 100%;
  background-color: #d8d8d8;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  font-size: ${(p) => p.theme.font.size.xs};

  div {
    text-align: center;
    width: 150px;
  }

  a {
    color: ${(p) => p.theme.palette.black};
    opacity: 0.7;
  }

  ${onDevice.mobile} {
    height: unset;
    flex-direction: column;
    justify-content: center;
    padding: 12px 0;

    div {
      margin: 0;
    }

    a {
      margin-bottom: 12px;
    }
  }
`;

const BackButton = styled.div`
  cursor: pointer;
  font-weight: 700;
`;

const PageTitle = styled(H1)`
  margin-top: 16px;

  ${onDevice.mobile} {
    font-size: ${(p) => p.theme.font.size.l};
    margin-bottom: ${(p) => p.theme.size.space.m};
  }
`;

const MissingExplanation = styled.div`
  line-height: 24px;
  margin-bottom: 32px;
`;

const StyledIcon = styled(Icon)`
  margin-right: 10px;
`;

const BackgroundImage = styled.div`
  position: absolute;
  left: 0;

  width: 100vw;
  padding: 5vw 15vw;
`;

const BackgroundDesktop = styled.img`
  display: block;

  ${onDevice.mobile} {
    display: none;
  }
`;

const BackgroundMobile = styled.img`
  display: none;
  opacity: 50%;

  ${onDevice.mobile} {
    display: block;
  }
`;

const SubPageWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

interface IParams {
  toLoginPage: () => void;
}

const RecoveryPage: FC<IParams> = ({ toLoginPage }) => {
  const { realm, actionUrl } = useContext(AuthContext);

  const [recoveryStage, setRecoveryStage] = useState<RecoveryStage>(
    RecoveryStage.EMAIL
  );
  const [pageTitle, setPageTitle] = useState<string>('');
  const [explanation, setExplanation] = useState<string>('');
  const [irmaSession, setIrmaSession] = useState<any>('');
  const [currentActionUrl, setCurrentActionUrl] = useState<string>(actionUrl);

  const year = new Date().getFullYear();

  const renderRecoveryStage = (stage: RecoveryStage): ReactElement => {
    switch (stage) {
      case RecoveryStage.EMAIL:
        return (
          <RecoveryEmail
            setRecoveryStage={setRecoveryStage}
            actionUrl={currentActionUrl}
            updateActionUrl={setCurrentActionUrl}
          />
        );
      case RecoveryStage.VERIFICATION_CODE:
        return (
          <RecoveryVerificationCode
            setRecoveryStage={setRecoveryStage}
            setIrmaSession={setIrmaSession}
            actionUrl={currentActionUrl}
            updateActionUrl={setCurrentActionUrl}
          />
        );
      case RecoveryStage.ISSUANCE:
        return (
          <RecoveryIrmaSession
            setRecoveryStage={setRecoveryStage}
            irmaSession={irmaSession}
            actionUrl={currentActionUrl}
            updateActionUrl={setCurrentActionUrl}
          />
        );
      case RecoveryStage.DONE:
        return <RecoveryDone />;
      default:
        console.error('Invalid recovery status');
        return <div></div>;
    }
  };

  useEffect(() => {
    const realmString = realm === 'ivido' ? 'Ivido' : 'HINQ';
    switch (recoveryStage) {
      case RecoveryStage.EMAIL:
        setPageTitle('Je toegangsbewijs ontbreekt');
        setExplanation(
          `Je toegangsbewijs ontbreekt in Yivi. Als je een ${realmString} account hebt kun je het toegangsbewijs met je e-mail weer opvragen.`
        );
        break;
      case RecoveryStage.VERIFICATION_CODE:
        setPageTitle('Er is een e-mail gestuurd');
        setExplanation(
          'Er is zojuist een e-mail verstuurd met daarin een toegangscode. Voer deze hier in om je toegangsbewijs opnieuw op te halen.'
        );
        break;
      case RecoveryStage.ISSUANCE:
        setPageTitle('Haal je Ivido kaartje op');
        setExplanation(
          'Je toegangscode is correct. Scan hier de QR om je nieuwe toegangsbewijs op te halen'
        );
        break;
      case RecoveryStage.DONE:
        setPageTitle('Gelukt!');
        setExplanation('Druk op de onderstaande knop om in te loggen');
        break;
      default:
        console.error('Invalid recovery status');
        break;
    }
  }, [recoveryStage]);

  return (
    <>
      <Main>
        <Paper width="500px" title="Login">
          <PageWrapper>
            <SubPageWrapper>
              <BackButton onClick={toLoginPage}>
                <StyledIcon icon={faChevronLeft} />
                Terug
              </BackButton>
              <PageTitle mb={24}>{pageTitle}</PageTitle>

              <MissingExplanation>{explanation}</MissingExplanation>

              {renderRecoveryStage(recoveryStage)}
            </SubPageWrapper>
          </PageWrapper>
        </Paper>
      </Main>
      {realm !== 'ivido' && (
        <Footer>
          <div id="footer-left"></div>
          <div id="footer-center">Copyright &#xa9; HINQ {year}</div>
        </Footer>
      )}
    </>
  );
};

export default RecoveryPage;
